import React from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";

const Header = ({
    companyLogo = null,
    hasAboutCare = false,
    hasTestimonials = false,
    hasAboutBE = false,
    hasStartLoanProcess = false,
    hero,
}) => {
    return (
        <>
            <Logo companyLogo={companyLogo} hero={hero} />
            <Navigation
                hasAboutCare={hasAboutCare}
                hasTestimonials={hasTestimonials}
                hasAboutBE={hasAboutBE}
                hasStartLoanProcess={hasStartLoanProcess}
            />
        </>
    );
};

export default Header;
