import React from "react";
import classnames from "classnames";

import styles from "./styles.module.less";
import heroes from "../../../../Hero/heroes.module.less";

const Logo = ({
    companyLogo = null,
    hero = {
        heroBackground: "careHeadingBackground",
        customPhoto: "",
        photoAlignment: "center",
    },
}) => {
    let cls = classnames(styles.header, {
        [heroes[hero.heroBackground]]: !hero.customPhoto,
    });
    let style = {
        backgroundPosition: hero.photoAlignment,
    };
    if (!!hero.customPhoto) {
        style.backgroundImage = `url("${hero.customPhoto}")`;
    }
    return (
        <div className={cls} style={style}>
            <div className={styles.containerFluid}>
                <div className={styles.row}>
                    <div className={styles.logoContainer}>
                        <div className={styles.logo}>
                            {companyLogo && (
                                <img
                                    data-blink-src={companyLogo}
                                    alt="Company Logo"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logo;
