import React from "react";

import styles from "./styles.module.less";

const Navigation = ({
    hasAboutCare = false,
    hasTestimonials = false,
    hasAboutBE = false,
    hasStartLoanProcess = false,
}) => {
    if (hasAboutBE || hasTestimonials || hasAboutBE || hasStartLoanProcess)
        return (
            <div className={styles.navContainer}>
                <div className={styles.containerFluid}>
                    <div className={styles.nav}>
                        {hasAboutCare && (
                            <div className={styles.navItem}>
                                <a href="#about-care">About the Program</a>
                            </div>
                        )}
                        {hasTestimonials && (
                            <div className={styles.navItem}>
                                <a href="#care-testimonials">Testimonials</a>
                            </div>
                        )}
                        {hasAboutBE && (
                            <div className={styles.navItem}>
                                <a href="#aboutBE">About Bay Equity</a>
                            </div>
                        )}
                        {hasStartLoanProcess && (
                            <div className={styles.navItem}>
                                <a href="#profile">Start the Loan Process</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    else return <></>;
};

export default Navigation;
