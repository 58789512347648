import React, { Component } from "react";
import { Helmet } from "react-helmet";

import styles from "./styles.module.less";

class AccessibilityLink extends Component {
    render() {
        const { title = "Enable Accessibility" } = this.props;

        const handleClick = e => {
            e.preventDefault();
            if (!!window.enableUsableNetAssistive) {
                window.enableUsableNetAssistive();
            }
        };

        return (
            <div>
                <Helmet className={styles.container}>
                    <script
                        src="https://bayequity.usablenet.com/pt/start"
                        type="text/javascript"
                        async
                    />
                </Helmet>

                <div className={styles.accessibilityLink}>
                    <a
                        href="_blank"
                        rel="noopener noreferrer"
                        className="UsableNetAssistive"
                        onClick={handleClick}>
                        {title}
                    </a>
                </div>
            </div>
        );
    }
}

export default AccessibilityLink;
